import { WebsiteInfoProps } from 'types/websiteInfo'
import contentful from 'utils/contentful'

export const getAll = async () => {
  const response = await contentful.getEntries({
    content_type: 'oooWebsiteInfo',
  })

  if (response.items.length == 0) return null

  return response.items[0].fields as unknown as WebsiteInfoProps
}

export default {
  getAll,
}
