import { useEffect, useState } from 'react'
import websiteInfoService from 'services/websiteInfoService'
import { WebsiteInfoProps } from 'types/websiteInfo'

export default function useWebsiteInfo() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<WebsiteInfoProps | null>(null)

  const fetchData = async () => {
    const items = await websiteInfoService.getAll()

    setData(items)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return { data, loading }
}
