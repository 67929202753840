import { useEffect, useState } from 'react'
import { getHomePage } from 'services/pageService'
import { HomePageProps } from 'types/homePage'

export default function useHomePage() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<HomePageProps | null>(null)

  const fetchData = async () => {
    const items = await getHomePage()

    setData(items)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return { data, loading }
}
