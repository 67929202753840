import React from 'react'
import './formTermsAndCo.Module.scss'

export default function FormTermsAndCo() {
  return (
    <div className="formTermsAndCo">
      <input type="checkbox" required />
      <label>
        I agree to the{' '}
        <a target="_blank" href="/#/termsAndConditions">
          Terms and Conditions
        </a>
      </label>
    </div>
  )
}
