import { useEffect, useState } from 'react'
import { getAboutPage } from 'services/pageService'
import { AboutPageProps } from 'types/aboutPage'

export default function useAboutPage() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<AboutPageProps | null>(null)

  const fetchData = async () => {
    const items = await getAboutPage()

    setData(items)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return { data, loading }
}
