import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from 'components/ui/button/button'
import ReservationPopup from 'pages/clubs/:name/components/reservationPopup/reservationPopup'
import GuestListPopup from 'pages/clubs/:name/components/guestListPopup/guestListPopup'
import './:slug.Module.scss'
import useEvent from 'hooks/useEvent'
import { EventProps } from 'types/event'
import ContentfulRichText from 'components/ui/richTextBox/richTextBox'

export default function EventsSlug() {
  const [visibility, setVisibility] = useState(false)
  const [guestVisibility, setGuestVisibility] = useState(false)

  const { slug } = useParams()
  const club = useEvent(slug as string)

  return (
    <>
      <div className="eventsSlug">
        <div className="eventsSlug__header">
          <div className="header__container">
            <div
              className="header__image"
              style={{ backgroundImage: `url(${club.data?.image})` }}
            ></div>
            <div className="header__body">
              <div className="body__header">
                <h3 className="header__title">{club.data?.title}</h3>
                <div className="header__item">
                  <label className="item__key">Location</label>
                  <label className="item__value">{club.data?.location}</label>
                </div>

                <div className="header__item">
                  <label className="item__key">Date</label>
                  <label className="item__value">{club.data?.date}</label>
                </div>

                <div className="header__item">
                  <label className="item__key">Opening time</label>
                  <label className="item__value">
                    {club.data?.openHour || '??:??'} -{' '}
                    {club.data?.closeHour || '??:??'}
                  </label>
                </div>
              </div>
              <div className="header__footer">
                <Button onClick={() => window.open(club.data?.url)}>
                  Guest List
                </Button>
                <Button onClick={() => setVisibility(true)}>
                  Reserve Table
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="clubName__body">
          {/* <h3 className="clubName__body__title">Description</h3> */}
          <div className="clubName__body__text">
            <ContentfulRichText content={club.data?.description} />
            {/* <label>{club.data?.title}</label> */}
          </div>
        </div>
      </div>
      <ReservationPopup
        club={club.data as EventProps}
        visibility={visibility}
        setVisibility={setVisibility}
      />
      {club.data && (
        <GuestListPopup
          visibility={guestVisibility}
          setVisibility={setGuestVisibility}
          club={club.data as EventProps}
        />
      )}
    </>
  )
}
