import { AboutPageProps } from 'types/aboutPage'
import { AmbassadorsPageProps } from 'types/ambassadorsPage'
import { ContactPageProps } from 'types/contactPage'
import { CorporatePageProps } from 'types/corporatePage'
import { HomePageProps } from 'types/homePage'
import { NewsletterPageProps } from 'types/newsletterPage'
import { RestaurantPageProps } from 'types/restaurantPage'
import { ServicesPageProps } from 'types/servicesPage'
import contentful from 'utils/contentful'

export const getHomePage = async () => {
  const response = await contentful.getEntries({
    content_type: 'oooHomePage',
  })

  if (response.items.length == 0) return null

  return { ...response.items[0].fields } as unknown as HomePageProps
}

export const getAboutPage = async () => {
  const response = await contentful.getEntries({
    content_type: 'oooAboutPage',
  })

  if (response.items.length == 0) return null

  //eslint-disable-next-line
  const image: any = response.items[0].fields.aboutImage

  return {
    ...response.items[0].fields,
    aboutImage: 'https:' + image.fields.file.url,
  } as AboutPageProps
}

export const getCorporatePage = async () => {
  const response = await contentful.getEntries({
    content_type: 'oooCorporatePage',
  })

  if (response.items.length == 0) return

  return response.items[0].fields as unknown as CorporatePageProps
}

export const getAmbassadorsPage = async () => {
  const response = await contentful.getEntries({
    content_type: 'oooAmbassadorsPage',
  })

  if (response.items.length == 0) return

  //eslint-disable-next-line
  const image: any = response.items[0].fields.image
  return {
    ...response.items[0].fields,
    image: 'https:' + image.fields.file.url,
  } as unknown as AmbassadorsPageProps
}

export const getServicesPage = async () => {
  const response = await contentful.getEntries({
    content_type: 'oooServicesPage',
  })

  if (response.items.length == 0) return

  return {
    ...response.items[0].fields,
  } as unknown as ServicesPageProps
}

export const getContactPage = async () => {
  const response = await contentful.getEntries({
    content_type: 'oooContactPage',
  })

  if (response.items.length == 0) return

  return {
    ...response.items[0].fields,
  } as unknown as ContactPageProps
}

export const getRestaurantPage = async () => {
  const response = await contentful.getEntries({
    content_type: 'oooRestaurantPage',
  })

  if (response.items.length == 0) return

  return {
    ...response.items[0].fields,
  } as unknown as RestaurantPageProps
}

export const getNewsletterPage = async () => {
  const response = await contentful.getEntries({
    content_type: 'oooNewsletterPage',
  })

  if (response.items.length == 0) return

  return {
    ...response.items[0].fields,
  } as unknown as NewsletterPageProps
}

export default {
  getHomePage,
  getAboutPage,
  getCorporatePage,
  getAmbassadorsPage,
  getServicesPage,
  getContactPage,
  getRestaurantPage,
}
