import PageTitle from 'components/ui/pageTitle/pageTitle'
import ContentfulRichText from 'components/ui/richTextBox/richTextBox'
import useWebsiteInfo from 'hooks/useWebsiteInfo'
import React from 'react'
import './termsAndConditions.Module.scss'

export default function TermsAndConditions() {
  const { data, loading } = useWebsiteInfo()

  if (loading) return <></>

  return (
    <div>
      <PageTitle>Terms And Conditions</PageTitle>
      <div className="termsAndConditions__container">
        <ContentfulRichText content={data?.termsAndConditions} />
      </div>
    </div>
  )
}
