import { useEffect, useState } from 'react'
import { getContactPage } from 'services/pageService'
import { ContactPageProps } from 'types/contactPage'

export default function useContactPage() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<ContactPageProps | null>(null)

  const fetchData = async () => {
    const items = await getContactPage()

    setData(items as ContactPageProps)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return { data, loading }
}
