import { useEffect, useState } from 'react'
import { getNewsletterPage } from 'services/pageService'
import { NewsletterPageProps } from 'types/newsletterPage'

export default function useNewsletterPage() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<NewsletterPageProps | null>(null)

  const fetchData = async () => {
    const items = await getNewsletterPage()

    setData(items as NewsletterPageProps)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return { data, loading }
}
