import { useEffect, useState } from 'react'
import { getAmbassadorsPage } from 'services/pageService'
import { AmbassadorsPageProps } from 'types/ambassadorsPage'

export default function useAmbassadorsPage() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<AmbassadorsPageProps | null>(null)

  const fetchData = async () => {
    const items = await getAmbassadorsPage()

    setData(items as AmbassadorsPageProps)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return { data, loading }
}
