import { useEffect, useState } from 'react'
import faqService from 'services/faqService'
import { FaqProps } from 'types/faq'

export default function useFaq() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<FaqProps[]>([])

  const fetchData = async () => {
    const items = await faqService.getAll()
    setData(items as unknown as FaqProps[])
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return { data, loading }
}
