import React from 'react'
import PageTitle from 'components/ui/pageTitle/pageTitle'
import ContentfulRichText from 'components/ui/richTextBox/richTextBox'
import useWebsiteInfo from 'hooks/useWebsiteInfo'
import './policyPrivacy.Module.scss'

export default function PolicyPrivacy() {
  const { data, loading } = useWebsiteInfo()

  if (loading) return <></>

  return (
    <div>
      <PageTitle>Privacy Policy</PageTitle>
      <div className="policyPrivacy__container">
        <ContentfulRichText content={data?.privacyPolicy} />
      </div>
    </div>
  )
}
