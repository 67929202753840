import { FaqProps } from 'types/faq'
import contentful from 'utils/contentful'

export const getAll = async () => {
  const items = await contentful.getEntries({
    content_type: 'oooFaq',
  })

  return items.items
    .map((item) => {
      return { ...item.fields } as unknown as FaqProps
    })
    .sort((a, b) => {
      return a.order - b.order
    })
}

export default {
  getAll,
}
